<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import store from "@/state/store";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    const permissions = store.getters["auth/permissions"];
    if (
      (userType === 1 || userType === 3) &&
      permissions.includes("HR_CONFIG")
    ) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Country Codes",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.refreshTable();
  },
  validations: {
    cc_name: { required },
    cc_code: { required },
  },
  data() {
    return {
      title: "Country Codes",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Country Codes",
          active: true,
        },
      ],
      codes: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      fields: [
        { key: "sn", label: "S/n", sortable: true, thStyle: { width: "5%" } },
        { key: "cc_name", label: "Country Name", sortable: true },
        { key: "cc_code", label: "Country Code", sortable: true },
      ],
      cc_name: null,
      cc_code: null,
      cc_id: null,
      submitted: false,
    };
  },

  methods: {
    refreshTable() {
      this.apiGet(this.ROUTES.countryCodes, "Get Country Codes Error").then(
        (res) => {
          const { data } = res;
          data.forEach((code, index) => {
            this.codes[index] = { sn: ++index, ...code };
          });
          this.totalRows = this.codes.length;
        }
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetForm() {
      this.bank_code = null;
      this.cc_name = null;
      this.$v.$reset();
    },
    selectCode(code) {
      code = code[0];
      this.cc_id = code.cc_id;
      this.cc_name = code.cc_name;
      this.cc_code = code.cc_code;
      this.$refs["update-code"].show();
      this.$refs["dept-table"].clearSelected();
    },
    submitNew() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.apiFormHandler("Invalid Country Code");
      } else {
        const data = {
          cc_name: this.cc_name,
          cc_code: this.cc_code,
        };
        this.apiPost(
          this.ROUTES.countryCodes,
          data,
          "Add Country Code Error"
        ).then((res) => {
          this.apiResponseHandler(`${res.data}`, "New Country Code Added");
          this.refreshTable();
          this.$v.$reset();
          this.$refs["add-code"].hide();
        });
      }
    },
    submitUpdate() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.apiFormHandler("Invalid Country Code");
      } else {
        const data = {
          cc_name: this.cc_name,
          cc_code: this.cc_code,
        };
        const url = `${this.ROUTES.countryCodes}/${this.cc_id}`;
        this.apiPatch(url, data, "Update Country Code Error").then((res) => {
          this.apiResponseHandler(`${res.data}`, "Update Successful");
          this.refreshTable();
          this.$v.$reset();
          this.$refs["update-code"].hide();
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-end mb-3">
      <b-button class="btn btn-success" @click="$refs['add-code'].show()">
        <i class="mdi mdi-plus mr-2"></i>
        Add Country Code
      </b-button>
    </div>

    <b-spinner type="grow" v-if="apiBusy" class="m-2" variant="success" />
    <div v-else class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="dept-table"
                bordered
                selectable
                hover
                :items="codes"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"
                @row-selected="selectCode"
              >
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="add-code"
      title="Add Country Code"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="submitNew">
        <div class="form-group">
          <label for="name">
            Country Name <span class="text-danger">*</span>
          </label>
          <input
            id="name"
            type="text"
            v-model="cc_name"
            class="form-control"
            :class="{
              'is-invalid': submitted && $v.cc_name.$error,
            }"
          />
        </div>
        <div class="form-group">
          <label for="cc_code">
            Country Code <span class="text-danger">*</span>
          </label>
          <input
            id="cc_code"
            type="text"
            v-model="cc_code"
            class="form-control"
            :class="{
              'is-invalid': submitted && $v.cc_code.$error,
            }"
          />
        </div>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>
    <b-modal
      ref="update-code"
      title="Update Code"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="submitUpdate">
        <div class="form-group">
          <label for="name">
            Country Name <span class="text-danger">*</span>
          </label>
          <input
            id=""
            type="text"
            v-model="cc_name"
            class="form-control"
            :class="{
              'is-invalid': submitted && $v.cc_name.$error,
            }"
          />
        </div>
        <div class="form-group">
          <label for="code">
            Country Code <span class="text-danger">*</span>
          </label>
          <input
            id="code"
            type="text"
            v-model="cc_code"
            class="form-control"
            :class="{
              'is-invalid': submitted && $v.cc_code.$error,
            }"
          />
        </div>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>
  </Layout>
</template>
